/* eslint-disable eqeqeq */
import React from 'react';
import './TimerMenu.css';
import api from 'services/Api/Api.js';

import Stopwatch from './Stopwatch/Stopwatch.js';
import moment from 'moment';
import autoBind from 'react-autobind';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSelect from 'common/ApSelect/ApSelect';
import ApPath from 'common/ApPath/ApPath.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import {QrReader} from 'react-qr-reader'
import { connect } from 'react-redux';
import ApTooltip from 'common/ApTooltip/ApTooltip';

// 5 minute interval
const TIMER_INTERVAL = 5 * 60 * 1000;
class TimerMenu extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            status: "stopped",
            startTime: false,
            stopTime: false,
            pauses: [],
            hoursWork: 0,
            hoursPause: 0,
            hoursGoal: 0,
            project: null,
            projectWorkId: null,
            assignmentId: null,
            hourTypeId: null,
            date: null,
            hourTypes: [],
            description: "",
            updated: Date.now(),
            entry_description_to_billable_dispatch_ref:false,
            readingQR: false,
            qrData: null,
            lat: null,
            lon: null,
            customer_work_number: null,

            lastTimeAttendanceSavePoint: null,
            loading: false,
        };
        // this.startTimer = this.startTimer.bind(this);
        // this.stopTimer = this.stopTimer.bind(this);
        // this.pauseTimer = this.pauseTimer.bind(this);
        // this.update = this.update.bind(this);
        // this.changeProjectWork = this.changeProjectWork.bind(this);
        // this.saveEntry = this.saveEntry.bind(this);
        // this.getLatestSavePoint = this.getLatestSavePoint.bind(this);
        // this.changeHourType = this.changeHourType.bind(this);
        // this.getSettings = this.getSettings.bind(this);
        // this.changeDescription = this.changeDescription.bind(this);
        autoBind(this);

        this.interval = null;
    }
    
    UNSAFE_componentWillMount() 
    {
        this.setState({ hoursGoal: 7.5 * 3600 }); // TODO = 7.5 | 8
        this.update();
    }

    componentWillUnmount() 
    {
        clearTimeout( this.tick );
    }

    componentDidMount() {
        this.getSettings();
        this.getLatestSavePoint()
            .then(( response ) => {
                if (response) {
                    let status = 'stopped';
                    if (response.type === 'in') {
                        status = 'playing';
                    } else if (response.type === 'break') {
                        status = 'paused';
                    }
                    let timestamp = false;
                    if (response.type === 'in') {
                        //timestamp = Math.floor(new Date(response.timestamp + 'Z').getTime() / 1000);
                        timestamp = Math.floor(new Date(Date.now() - response.seconds_from_start*1000).getTime() / 1000);
                    }
                    if (response.assignment!=null) {
                        response.project.assignment=response.assignment;
                    }
                    let pauses = [];
                    if (response.breaks) {
                        pauses = response.breaks;
                        // check last if last break has no end time
                        if (pauses.length > 0 && pauses.at(-1).stop === false) {
                            status = 'paused';
                        }
                    }
                    this.setState({
                            project: response.project,
                            projectWorkId: response.project_work_id,
                            assignmentId: response.assignment_id,
                            date: response.date,
                            status: status,
                            startTime: timestamp,
                            description: response.description ? response.description : "",
                            entry_description_to_billable_dispatch_ref: response.entry_description_to_billable_dispatch_ref,
                            lat: response.location_lat,
                            lon: response.location_lon,
                            customer_work_number: response.customer_work_number,
                            pauses: pauses,
                    
                    });
                }
            }).catch( ( error ) => {
                 /* this.setState({ 
                    loading: false, 
                    error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
                });  */
            });
        this.getDayIsLocked();
        const interval = setInterval(() => {
            this.getTimeAttendanceLastPoint();
        }, TIMER_INTERVAL);
        this.interval = interval;
    }

    getTimeAttendanceLastPoint = async () => {
        if (!this.isTimeAttendanceValid()) return;

        return api({
            url: 'timeAttendance/lastSavePoint',
            method: 'get',
        }).then(( response ) => {
            // console.log(response);
            this.setState({lastTimeAttendanceSavePoint: response}, () => {
                this.props.setTimeAttendance({
                    lastTimeAttendanceSavePoint: response,
                    isSignedInTimeAttendance: this.isCheckedIn(),
                });
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open) {
            this.setState({readingQR: false});
        }

        if (!prevProps.apTimetrackingSettings?.time_attendance?.length && this.props.apTimetrackingSettings?.time_attendance?.length) {
            this.getTimeAttendanceLastPoint();
        }

        if (this.state.status !== prevState.status) {
            this.props.statusChange(this.state.status);
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getLatest() {
        this.getLatestSavePoint()
        .then(( response ) => {
            if (response) {
                let status = 'stopped';
                if (response.type === 'in') {
                    status = 'playing';
                } else if (response.type === 'break') {
                    status = 'paused';
                }
                this.setState(prevState => {
                    let timestamp = false;

                    if (response.type == 'in') {
                        //timestamp = Math.floor(new Date(response.timestamp + 'Z').getTime() / 1000);
                        timestamp = Math.floor(new Date(Date.now() - response.seconds_from_start*1000).getTime() / 1000);
                    }
                    if (response.assignment!=null) {
                        response.project.assignment=response.assignment;
                    }
                    let pauses = [];
                    if (response.breaks) {
                        pauses = response.breaks;
                        // check last if last break has no end time
                        if (pauses.length > 0 && pauses.at(-1).stop === false) {
                            status = 'paused';
                        }
                    }
                    return {
                        project: response.project,
                        assignmentId: response.assignment_id,
                        projectWorkId: response.project_work_id,
                        date: response.date,
                        status: status,
                        startTime: timestamp,
                        description: response.description ? response.description : "",
                        lat: response.location_lat,
                        lon: response.location_lon,
                        customer_work_number: response.customer_work_number,
                        pauses: pauses,
                    }
                });
            }
            else {
                this.setState(prevState => {
                    return {
                        status: "stopped",
                        stopTime: Math.floor(new Date().getTime / 1000) 
                    }
                });
            }
        }).catch( ( error ) => {
             /* this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            });  */
        });
    }

    getSettings()
    {
        api({
            method: 'get',
            url: 'timetracking/settings/entry',
        }).then(( settings  ) => {
            
            if (settings && settings.hour_types) {
                this.setState({ hourTypes: settings.hour_types });
            }

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
        });
    }

    async getDayIsLocked() {
        return api({
            method: 'get',
            url: 'timetracking/locked-day',
        }).then(( response ) => {
            this.props.dayIsLocked(!!response);
            return response;
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
        });
    }

    startTimer() 
    {
        this.setState({
            status: "playing",
            startTime: Math.floor(new Date().getTime() / 1000),
            stopTime: false,
            pauses: []
        }, () => {
            if (this.state.project && this.state.projectWorkId) {
                this.saveEntry();
            }
        });
        this.update();
    }

    async stopTimer(saveEntry =true) 
    {
        return new Promise((resolve, reject) => {
            this.setState({
                status: "stopped",
                stopTime: Math.floor(new Date().getTime / 1000) 
            }, async () => {
                if (this.state.project && this.state.projectWorkId) {
                    if (saveEntry) {
                        await this.saveEntry();
                    }
                    // clear input fields after entry was made
                    this.setState({
                        project: null,
                        projectWorkId: null,
                        assignmentId: null,
                        hourTypeId: null,
                        description: "",
                        customer_work_number: null,
                    });
                }
            });
            this.update();
            resolve();
        });
    }

    setReadingQR = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                // console.log(position);
                this.setState({lat: position.coords.latitude, lon: position.coords.longitude, readingQR: !this.state.readingQR});
            }, (error) => {
                console.error(error);
                this.setState({readingQR: !this.state.readingQR});
            });
        }
    }

    handleScan = async (data, error) => {
        if (error && error.message == 'Permission denied') {
            errorPopper(error, tr('camera_permission_denied'));
            return;
        }
        if (data) {
            // console.log(data);
            this.setState({qrData: data, readingQR: false});
            let qrData = data.text?.split(" ");
            /* data in data.text string:
            qrData[0] should always be easypro
            p_id=project_id
            w_id=project_work_id
            a_id=assignment_id - optional
            h_id=hour_type_id
            info=description
            */
           
            if (qrData[0] !== "easypro") {
                errorPopper(null, tr('qr_code_error'));
                return;
            }

            let qrDataObject = {};
            qrData.forEach((value) => {
                let split = value.split("=");
                qrDataObject[split[0]] = split[1];
            });

            const project = await this.getProject(qrDataObject.p_id);
            if(project && qrDataObject.p_id && qrDataObject.w_id && qrDataObject.h_id) {
                this.setState({
                    project: project,
                    projectWorkId: qrDataObject.w_id,
                    project_id: qrDataObject.p_id,
                    hourTypeId: qrDataObject.h_id,
                    assignmentId: qrDataObject.a_id,
                    description: qrDataObject.info,
                }, () => {
                    this.startTimer();
                });
            }
        }
    }

    getProject = async (project_id) => {
        return api({
            method: 'post',
            url: 'search/project/find',
            data: {
                project_id: project_id,
                with_ancestors: true,
                with_works: true,
            }
        }).then(( response ) => {
            // console.log(response);
            return response;
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
        });
    }

    getSaveEntryData(stopBreak = false, type = null) {
        // let type = null;
        let timestamp = null;
        if (type == null ) {
            if (this.state.status === 'playing') {
                type = 'in';
                timestamp = this.state.startTime;
            } else if (this.state.status === 'stopped') {
                type = 'out'
                timestamp = this.state.stopTime;
            } else if (this.state.status === 'paused') {
                type = 'break_start';
                timestamp = this.state.stopTime;
            } else {
                type = 'out';
                timestamp = this.state.stopTime;
            }
            if (stopBreak) {
                type = 'break_end';
                timestamp = this.state.stopTime;
            }
        }
        
        const description_to_billable_is_mandatory = this.props.apOrderSettings?.timetracking_description_to_billable_is_mandatory;
        const data = {
            project_id: this.state.project.id,
            project_work_id: this.state.projectWorkId,
            hour_type_id: this.state.hourTypeId,
            assignment_id: this.state.assignmentId,
            date: moment().format('YYYY-MM-DD'),
            timestamp: new Date().toUTCString(),
            type: type,
            description: this.state.description,
            entry_description_to_billable_dispatch_ref: this.state.entry_description_to_billable_dispatch_ref || description_to_billable_is_mandatory,
            lat: this.state.lat,
            lon: this.state.lon,
            customer_work_number: this.state.customer_work_number,
        };

        return data;
    }

    async saveEntry(stopBreak = false) {

        // let type = null;
        // let timestamp = null;
        // if (this.state.status === 'playing') {
        //     type = 'in';
        //     timestamp = this.state.startTime;
        // } else if (this.state.status === 'stopped') {
        //     type = 'out'
        //     timestamp = this.state.stopTime;
        // } else if (this.state.status === 'paused') {
        //     type = 'break_start';
        //     timestamp = this.state.stopTime;
        // } else {
        //     type = 'out';
        //     timestamp = this.state.stopTime;
        // }
        // if (stopBreak) {
        //     type = 'break_end';
        //     timestamp = this.state.stopTime;
        // }

        this.setState({loading: true});
        return api({
            method: 'post',
            url: 'timetracking/timer/createSavePoint',
            data: this.getSaveEntryData(stopBreak),
            // data: {
            //     project_id: this.state.project.id,
            //     project_work_id: this.state.projectWorkId,
            //     hour_type_id: this.state.hourTypeId,
            //     assignment_id: this.state.assignmentId,
            //     date: moment().format('YYYY-MM-DD'),
            //     timestamp: new Date().toUTCString(),
            //     type: type,
            //     description: this.state.description,
            //     entry_description_to_billable_dispatch_ref: this.state.entry_description_to_billable_dispatch_ref,
            //     lat: this.state.lat,
            //     lon: this.state.lon,
            //     customer_work_number: this.state.customer_work_number,
            // }
        }).then(( response ) => {
            
        }).catch( ( error ) => {
            /* this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            }); */
            errorPopper(error, tr('save_error'));
            if (type === 'in') {
                this.setState({
                    status: "stopped",
                    startTime: false,
                    stopTime: false,
                    hoursWork: 0,
                })
            }
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    pauseTimer() 
    {

        var now = Math.floor(new Date().getTime() / 1000);
        var pauses = this.state.pauses;
        
        // Start new pause
        if( this.state.status == "playing" ) {
            pauses.push( {
                start: now,
                stop: false
            });
            this.setState({ 
                status: "paused",
                pauses: pauses
            }, () => {
                if (this.state.project && this.state.projectWorkId) {
                    this.saveEntry();
                }
            });
        }
        // Stop last pause
        else if (this.state.status == "paused" ) {
            pauses[ pauses.length - 1 ].stop = now;
            this.setState({ 
                status: "playing",
                pauses: pauses
            }, () => {
                if (this.state.project && this.state.projectWorkId) {
                    this.saveEntry(true);
                }
            });
        }
        this.update();
        
    }

    getLatestSavePoint() {
        return api({
            method: 'post',
            url: 'timetracking/timer/getLatestSavePoint',
            data: {
                date: moment().format('YYYY-MM-DD')
            }
        });
    }

    update() {
        if (this.state.updated<this.props.updateTimer) {
            this.setState({updated:this.props.updateTimer});
            this.getLatest();
        }

        if( this.state.status == "playing" || this.state.status == "paused" ) {

            var newState = {
                hoursWork: 0,
                hoursPause: 0
            }

            if( this.state.startTime ) {

                var now = Math.floor(new Date().getTime() / 1000 );
                var endTime = ( this.state.stopTime ? this.state.stopTime : now );
                // var goalTime = this.state.startTime + ( this.state.hours * 3600 ); 

                var total = endTime - this.state.startTime;
                var pause = 0;

                for( var i = 0; i < this.state.pauses.length; i++ ) {
                    var pauseEnd = ( this.state.pauses[i].stop ? this.state.pauses[i].stop : now );
                    pause += pauseEnd - this.state.pauses[i].start;
                }
                
                newState = {
                    hoursWork: total - pause,
                    hoursPause: pause
                };

            }
        }

        this.setState( newState );

        clearTimeout( this.tick );
        this.tick = setTimeout( this.update, (this.props.open ? 1000 : 10000 ));
    }

    secondsToHms( seconds ) {
        var h = Math.floor( seconds / 3600 );
        var m = Math.floor( ( seconds % 3600 ) / 60 );
        var s = Math.floor( seconds % 60 );

        return {
            h: ("0" + h).substr(-2),
            m: ("0" + m).substr(-2),
            s: ("0" + s).substr(-2)
        };
    }

    onAddProject(date, project) {
    	//console.log(project);
        this.setState(prevState => {
            let projectWorkId = null;
            let assignmentId = null;
            if (project.works && project.works.length > 0) {
                projectWorkId = project.works[0].id
            }
            if (project.assignment!==undefined) {
            	assignmentId = project.assignment.id
            }
            return {
                project: project,
                date: date,
                projectWorkId: projectWorkId,
                assignmentId: assignmentId
            };
        });
    }

    getWorkOptions() {
        let options = [];
        if (this.state.project && this.state.project.works) {
            this.state.project.works.forEach( w => {
                options.push({
                    value: w.id,
                    label: w.name
                });
            });
        }

		return options;
    }

    getHourTypeOptions()
	{
		const hourTypes = this.state.hourTypes;

		let options = [];
		hourTypes.forEach( t => {
			options.push({
				value: t.id,
				label: t.name,
			});
        })
        
        if (options.length > 0 && !this.state.hourTypeId) {
            this.setState({
                hourTypeId: options[0].value
            });
        }

		return options;
	}

    changeProjectWork(event) {
        this.setState({
            projectWorkId: event.target.value
        });
    }

    changeHourType(event) {
        this.setState({
            hourTypeId: event.target.value
        });
    }

    changeDescription(event) {
        this.setState({
            description: event.target.value
        });
    }
    changeDescriptionToBillableDispatchRef(event) {
        let entry_description_to_billable_dispatch_ref = this.state.entry_description_to_billable_dispatch_ref;
        this.setState({
            entry_description_to_billable_dispatch_ref: !entry_description_to_billable_dispatch_ref
        });
    }
    
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    
    validateDescription()
    {
    	const desc = this.state.description;

        let required = false;
        if (this.state.hourTypeId) {
            if( this.state.hourTypes.find( t => t.id == this.state.hourTypeId).is_entry_description_required)
                required = true;
        }

    	if( required && !desc )
    		return false;

        return true;
    }

    timestampToTime( timestamp, format )
    {
        if( timestamp ) {
            var date = new Date(timestamp*1000);
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();

            if( typeof( format ) == "string" ) {
                return format.replace('hh', ("0" + hours).substr(-2))
                             .replace('mm', ("0" + minutes).substr(-2))
                             .replace('ss', ("0" + seconds).substr(-2))
                             .replace('h', hours )
                             .replace('m', minutes )
                             .replace('s', seconds );
            }
            return {
                h: hours,
                m: minutes.substr(-2),
                s: seconds.substr(-2)
            };
        }
        return false;
    }

    isTimeAttendanceValid = () => {
        if (!this.props.apTimetrackingSettings?.time_attendance?.length) return false;
        return !!this.props.apTimetrackingSettings.time_attendance.find(d => d.timer_time_attendance && d.active) || false;
    }

    renderBottomButtons = () => {
        //TODO: if have time fix some tooltip from this time
        // const mitutesFromTimestampIn = this.state.lastTimeAttendanceSavePoint?.timestamp_start != null ?
        //     moment().diff(this.state.lastTimeAttendanceSavePoint?.timestamp_start, 'minutes'): null;
        return <div className='corner-buttons-bottom'>
            <button className="cornerButton left apTooltipper" onClick={this.handleSignInOut} disabled={this.isCheckedIn() || this.state.timeAttendanceLoading || this.state.loading}>
                <SvgIcon type="solid" icon="sign-in-alt" />
                <span className="apTooltip bottom position-topleft delayed">{tr('sign_in_time_attendance')}</span>
            </button>
            {/* <div className="apTooltip bottom">

                {Math.floor(mitutesFromTimestampIn / 60) + ':' + mitutesFromTimestampIn % 60}
                
            </div> */}

            <button className="cornerButton right apTooltipper" onClick={this.handleSignInOut} disabled={!this.isCheckedIn() || this.state.timeAttendanceLoading || this.state.loading}>
                <SvgIcon type="solid" icon="sign-out-alt" />
                <span className="apTooltip bottom position-topright delayed">{tr('sign_out_time_attendance')}</span>
            </button>
        </div>;
    }

    handleSignInOut = async () => {
        const foundDevice = this.props.apTimetrackingSettings?.time_attendance.find(d => d.timer_time_attendance && d.active);
        this.setState({timeAttendanceLoading: true})

        // stop timer if running before signing out
        // if (this.state.status === 'playing' && this.isCheckedIn()) {
        //     await this.stopTimer(false);
        // }

        // TODO: korjaa tämä pika fixi, että ei tarvitse odottaa hetkeä että kello on pysähtynyt jotta voidaan tehdä kulunvalvonnan laskut
        // await new Promise(r => setTimeout(r, 1000));
        let stopTimerData = {};
        if (this.state.status === 'playing' && this.isCheckedIn()) {
            stopTimerData = this.getSaveEntryData(false, "out");
        }

        return api({
            method: 'post',
            url: 'timetrackin/external/timeattendance/submit',
            data: {
                id: null,
                timer: true,
                token: foundDevice?.token,
                timestamp: new Date().toISOString(),
                mode: this.isCheckedIn() ? 'out' : 'in',
                stopTimerData: stopTimerData,
            },
        }).then(( response ) => {
            // console.log("res", response);
            this.getTimeAttendanceLastPoint();

            // stop timer if running before signing out
            this.setState({ timeAttendanceLoading: false }, () => {
                if (this.state.status === 'playing' && this.isCheckedIn()) {
                    this.setState({
                        status: "stopped",
                        stopTime: Math.floor(new Date().getTime / 1000)
                    }, () => {
                        this.stopTimer(false);
                    });
                }
            });
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({timeAttendanceLoading: false});
        });
    }

    isCheckedIn = () => {
        let checkedIn = false;
        if (this.state.lastTimeAttendanceSavePoint?.timestamp_end === null) checkedIn = true;
        if (this.state.lastTimeAttendanceSavePoint?.timestamp_start && this.props.apTimetrackingSettings?.company?.time_attendance_time_limit) {
            const limitMinutes = this.props.apTimetrackingSettings?.company?.time_attendance_time_limit;
            // check if current time is more than limit minutes from last time attendance start
            // so we know if new time attendance save point should be made
            const timeDiff = moment().diff(moment(this.state.lastTimeAttendanceSavePoint.timestamp_start), 'minutes');
            // console.log("DIFF", timeDiff, limitMinutes);
            if (timeDiff > limitMinutes) checkedIn = false;
        }
        return checkedIn;
    }

    qrButtonDisabled = () => {
        let disabled = false;
        if (this.state.status !== 'stopped') disabled = true;
        if (this.props.apTimetrackingSettings?.company?.timer_time_attendance_required 
        && !this.isCheckedIn()) {
            disabled = true;
        }
        if (this.props.apTimer?.dayIsLocked) disabled = true;
        return disabled;
    }

    render()
    {

        var icon = "clock";
        if( this.state.status == "playing" )        icon = "play-circle";
        else if ( this.state.status == "paused" )   icon = "pause-circle";

        var hms = this.secondsToHms( this.state.hoursWork );

        let projectPath = [];
        if( this.state.project)
        {
            if (this.state.project.ancestors.length > 0) {
                this.state.project.ancestors.forEach( a => {
                    projectPath.push( a.name );
                });
            }
            projectPath.push( this.state.project.name );
        }

        const description_to_billable_is_mandatory = this.props.apOrderSettings?.timetracking_description_to_billable_is_mandatory;
       
        return (
            <div className={"block timer " + this.state.status }>
                <div className="button" onClick={() => { this.props.toggleModal("timer"); setTimeout( this.update, 500 ); } }>
                    { this.state.status != "stopped" && (
                        <span className="text">{ hms.h + ":" + hms.m }</span>
                    )}
                    <SvgIcon icon={icon} />
                </div>
                <div id="timer-modal" className={"topModal" + (this.props.open ? " open" : "" ) }>
                    <div className="padding-small">

                        <div className="bg">

                            {/* <button className="cornerButton left apTooltipper">
                                <SvgIcon type="solid" icon="car" />
                                <span className="apTooltip bottom delayed">Lisää matka</span>
                            </button>

                            <button className="cornerButton right apTooltipper">
                                <SvgIcon type="solid" icon="history" />
                                <span className="apTooltip bottom delayed">Näytä historia</span>
                            </button> */}
                            {this.state.readingQR ?
                                <QrReader
                                    scanDelay={500}
                                    onResult={this.handleScan}
                                    style={{ width: '100%'}}
                                    constraints={{ facingMode: "environment" }}
                                />
                            : null}
                            {!this.state.readingQR ? <Stopwatch 
                                value={ this.state.hoursWork }
                                max={ this.state.hoursGoal }
                                status={ this.state.status } 
                                time={ hms } 
                            /> : null}
                            { this.isTimeAttendanceValid() 
                                ? this.renderBottomButtons()
                                : null
                            }
                            <div className="controls">
                                <button
                                    className="apButton green large iconTop"
                                    onClick={this.startTimer}
                                    disabled={(this.state.status != "stopped" ? true : false) || !this.state.project}
                                >
                                    <SvgIcon type="solid" icon="sign-in-alt" />
                                    <span>{tr('sign_in')}</span>
                                </button>
	                            
                                {this.state.status === 'stopped' && 
                                <button 
                                    className="apButton green large iconTop" 
                                    onClick={this.setReadingQR} 
                                    disabled={this.qrButtonDisabled()}
                                >
	                                <SvgIcon type="solid" icon="qrcode" />
	                                <span>{tr('qr_code')}</span>
	                            </button>}
                                {this.state.status !== 'stopped' && 
                                <button className="apButton orange large iconTop" onClick={this.pauseTimer} disabled={(this.state.status == "playing" || this.state.status == "paused" ? false : true)}>
                                    <SvgIcon type="solid" icon="coffee" />
                                    <span>{this.state.status != "paused" ? tr("start_break") : tr("end_break")}</span>
                                </button> }
	                            <button
	                                className="apButton red large iconTop"
	                                onClick={this.stopTimer}
	                                disabled={ !(this.state.status === "playing" && this.validateDescription()) }
	                            >
	                                <SvgIcon type="solid" icon="sign-out-alt" />
	                                <span>{ tr('sign_out') }</span>
	                            </button>
	                        </div>
	                        
                            {this.state.project && (
                                    <div className={ "project empty timer-project" } key={ this.state.date + "_" + this.state.project.id }>
                                        <div className="title">
                                            <SvgIcon icon={"folder-open"} type="solid" className="hideUnder800"/>
                                            <div className="name">
                                            	{this.state.project.assignment!==undefined? <strong>{this.state.project.assignment.name}<br /></strong>:""}
                                                <strong><ApPath items={ projectPath } boldLast /></strong><br />
                                                <small>{ this.state.project.project_code }</small>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            <ApTooltip 
                                text={(this.props.apTimetrackingSettings?.company?.timer_time_attendance_required
                                    && !this.isCheckedIn())
                                    ? tr('sign_in_time_attendance_required')
                                    : this.props.apTimer?.dayIsLocked 
                                        ? tr('day_locked') 
                                        : null}
                                block
                            >
                                <ApSelect
                                    label={ tr('select_project_assignment') }
                                    disabled={
                                        this.state.status === 'playing' 
                                        || this.state.status === 'paused'
                                        || (this.props.apTimetrackingSettings?.company?.timer_time_attendance_required && !this.isCheckedIn())
                                        || this.props.apTimer?.dayIsLocked
                                    }
                                    value={ "" }
                                    optionRenderer="project_assignment"
                                    onChange={ (e) => this.onAddProject( moment().format('YYYY-MM-DD'), e ) }
                                    objKeyId="id"
                                    clearable
                                    request_time= {true}
                                    apiUrl="timetracking/projects"
                                    apiData={{ 
                                        date: moment().format('YYYY-MM-DD'),
                                        //exlude: [].map( p => p.id ),
                                    }}
                                />
                            </ApTooltip>

                            <ApInputStack gap="0">
                                <ApAddon noRightBorder width="150">
                                    { tr('work_title') }
                                </ApAddon>
                                <ApInput 
                                    type="select" 
                                    id="project_work_id" 
                                    name="project_work_id"
                                    disabled={this.state.status === 'playing' || this.state.status === 'paused'}
                                    value={ this.state.projectWorkId ? this.state.projectWorkId : '' }
                                    options={ this.getWorkOptions() }
                                    onChange={this.changeProjectWork}
                                />
                            </ApInputStack>
                            <ApInputStack gap="0">
                                <ApInput 
                                    type="text" 
                                    id="description" 
                                    name="description" 
                                    label={ tr('description') }
                                    value={this.state.description}
                                    onChange={this.changeDescription}
                                    validationState={this.validateDescription() ? "" : "error"}
                                    maxLength={255}
                                    charCounter
                                />
                                {this.props?.apOrderSettings?.show_in_timetracking_description_to_billable &&
                                    < ApAddon noRightBorder width="85" noPadding >
                                    <ApTooltip text={tr('description_to_billable')} position="topright">
                                        <SvgIcon icon="cash-register" type="solid" style={{}} />
                                        <ApSwitch
                                            small
                                            inline
                                            id="clock-description-to-billable-switch"
                                            on={this.state.entry_description_to_billable_dispatch_ref || description_to_billable_is_mandatory}
                                            disabled={description_to_billable_is_mandatory}
                                            onChange={this.changeDescriptionToBillableDispatchRef}
                                            />
                                    </ApTooltip>
                                </ApAddon>}
                                
                                
                            </ApInputStack> 

                            
                            
                            <ApInput 
                                type="text" 
                                id="customer_work_number" 
                                name="customer_work_number" 
                                label={ tr('customer_work_number') }
                                value={this.state.customer_work_number}
                                onChange={this.handleChange}
                                maxLength={255}
                                charCounter
                            />

                            <ApInput 
                                gapRight="10"
                                type="select" 
                                id={ "hour_type_id" } 
                                name={ "hour_type_id" }
                                value={ this.state.hourTypeId }
                                disabled={this.state.status === 'playing' || this.state.status === 'paused'}
                                options={ this.getHourTypeOptions() }
                                onChange={this.changeHourType}
                            />

                        </div>

                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = ( state ) => {
    return {
        apTimetrackingSettings: state.apTimetrackingSettings,
        apTimer: state.apTimer,
        apOrderSettings: state.apOrderSettings,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        statusChange: ( status ) => {
            dispatch({
                type: 'STATUS_CHANGE',
                payload: status
            });
        },
        dayIsLocked: ( locked ) => {
            dispatch({
                type: 'DAY_IS_LOCKED',
                payload: locked
            });
        },
        setTimeAttendance: ( timeAttendance ) => {
            dispatch({
                type: 'SET_TIME_ATTENDANCE',
                payload: timeAttendance
            });
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimerMenu);

